@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500&display=swap');


body{
    font-family: 'Roboto', sans-serif !important;
}





.titolo{
    text-align: center;
}

.lpbody img{
    max-width: 193px;
    text-align :center;
    margin: 0 25%;
}

.lpbody{
    text-align: center;
}


.lp1 p.testofinale{
    line-height: 2ch;
}

.lp1 .lpbody h2{
    margin:0;
    
    line-height: 2ch;
}

.lp1 .lpbody h4{
    margin:0;
    
    line-height: 2ch;
}

.lp1 .text_center.pregunta{
    margin-bottom: 5px;;
}

.lp1 .testoDomanda{
    margin-bottom: 5px;
    line-height: 2ch;
}


.lpBackground{
	width: 100%;
	height: 100%;
	min-width: 100%;
	min-height: 100%;
	background-image: url('./img/sfondo.jpg');
    background-size: cover;
}

.lpbody h2{
    font-size: 1.4em;
}

.lpbody h4{
    color: #128c7e;
    text-align: center;
    font-size: 20px;
}

.lpbody p{
    font-size: 1em;
    font-weight: 400;

}

.testoDomanda{
    font-weight: 800 !important;
}

.text_center{
    text-align: center;
}

.indexPregunta{
    padding: 5px 7px;

}

.selectedPregunta{
    background-color: #128c7e;
    color:#fff
}

.itemSelezioni{
    padding: 10px;
    position: relative;
}

.itemSelezioni img{
    max-width: 100%;
    width: 100%;
    margin:0;
}

.checkboxSelected{
    position: absolute;
top: 10px;
left: 19px;
color: green;
font-size: 2em;
opacity: 0;
}

.visible{
    opacity: 1 !important;
}

.descrizioneSelezioni{
    padding: 10px;
    background-color: #128c7e;
    display: block;
    font-weight: 800;
    color:#fff;
}

.descrizioneSelezioniSelected{
    background-color: #6dab3c;
    border: 1px solid #6dab3c;
    padding: 10px;
    display: block;
    font-weight: 800;
    color:#fff;
}

.contieniPulsanti{
    width: 100%;
}

.lp1.modale_landing button.btn {
    width: 50%;
    border-radius: 0;
    border: 1px solid #fff;
    float: left;
    margin: 0;
    padding: 6px;
    height: 38px;
    font-family: 'Roboto', sans-serif !important;
    letter-spacing: 1px;
    font-size: 1em;
    line-height: normal;
}

.modale_landing button.btn.unico{
    width: 100% !important;
}

.modale_landing .modal-footer .btn-primary{
    background-color: #3EC028;
}


.modale_landing .modal-footer .btn-secondary{
    background-color: #3EC028;
    color: #fff;
}