.lp4 .mobile_center {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
  }

  .lp4 .text-center{
    text-align: center;
  }

  .lp4 .img_avviso{
    margin-bottom: 10px;
  }

  .lp4 .titolo_disclaimer{
    background-color: #F35252;
    color: #fff;
  }

  .lp4 .titolo_disclaimer p{
    text-align: center;
    line-height: 19px;
    font-size: 19px;
    padding: 10px;
    margin: 0;
    font-weight: 300;
  }


  .lp4 .titolo_disclaimer p strong {
    color: #fff;
    margin-top: 5px;
    display: block;
  }

  .lp4 .regole h3{
    font-size: 16px;
    text-align: left;
    margin: 0;
  }

  .lp4 .regole p{
    text-align: left;
    line-height: 16px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .lp4 button.unico{
    width: 100%;
  }

           
  .lp4 .btn-grad {
    margin-top: 20px;
    background-image: linear-gradient(to right, #F35252 0%, #E25050  51%, #CC4C4C  100%);
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
    width: 100%;
    border: none;
    height: 40px;
    line-height: normal;
    font-family: "Roboto", sans-serif;
    letter-spacing: 2px;
  }

  .lp4 .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
 